import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from 'axios';

function App() {
  const handleLogin = () => {
    window.location.href = 'https://devapi.unalivio.com/users/google-signin' //Replace with backend url
  }

  return (
    <div className="App">
      <button onClick={handleLogin}>Login with Google</button>
      <Router>
        <Routes>
          <Route path="/oauth/callback" element={<OauthCallback />} />
        </Routes>
      </Router>
    </div>
  );
}

function OauthCallback() {
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const error = params.get('error');

    if (token) {
      console.log(`Received JWT: ${token}`);
      localStorage.setItem('jwt', token);
      setToken(token);
      
      axios.get('https://devapi.unalivio.com/services/get-categories', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(res => {
        console.log(res.data);
        setApiResponse(res.data);
      })
      .catch(err => {
        console.error("API request failed: ", err);
      });

    } else if (error) {
      console.log(`An error occurred during OAuth flow: ${error}`);
    }
  }, [location]);

  const copyToken = () => {
    navigator.clipboard.writeText(token)
      .then(() => {
        alert("Token copied to clipboard");
      })
      .catch(err => {
        console.error("Could not copy text: ", err);
      });
  }

  return (
    <div>
      {token && <button onClick={copyToken}>Copy Token</button>}
      {apiResponse && <div><pre>{JSON.stringify(apiResponse, null, 2)}</pre></div>}
    </div>
  );
}

export default App;
